@import 'variables';

@import '../common';

header#page-header #page-logo{
	background:url('/esipro/images/logo.png') no-repeat center center;
	background-size: contain;

	.logopf{
		   display:none;
	}

	.logoesi{
		visibility:hidden;
	}
}

   .my-space-menu   {
	ul.menu {
		.menu-item {
			.menu-link {
				border-bottom: 0.0625rem solid $white-color;
			}

			&:last-child .menu-link {
				border-bottom: none;
			}
		}
	}
}

/*
.r-tabs {
    position: relative;
    background-color: $pink-color;
    border-top: 1px solid  $pink-color;
    border-right: 1px solid  $pink-color;
    border-left: 1px solid  $pink-color;
    border-bottom: 1px solid  $pink-color;
}
.r-tabs .r-tabs-nav .r-tabs-tab {
    background-color: $pink-color;
}

.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor{
    color: #fff !important;
    background-color: $red-color;
}

.r-tabs .r-tabs-nav .r-tabs-anchor {
    color: #fff !important;
}

*/

/*
.off-canvas-wrapper .off-canvas-wrapper-inner .off-canvas-content section.main-content {
    background: #40B8E1;
}

.content-body {
    border-radius: 25px;
}

*/