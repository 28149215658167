
footer{
	background-color: $black-color;
    min-height: 85px;	

    table{
    	text-align:center;

    	
    	tbody{
			background:none !important;
			border:none !important;
    		tr{
    			background:none !important;
    			border:none !important;
    		}
    	}
    }
}

@media screen and (max-width:767px){
    header#page-header .top-bar.main-nav{
        display:none;
    }
    
    header#page-header .top-bar{
        width: 100% !important;

        ul{
            display: table;
            width: 100%;    

            li{
                text-align:center;
                height:52px;
            }
        }
    }

    footer table{
        display:block;

        tbody{
            display:block;

            tr{
                display:block;

                td{
                    display:block;
                    text-align:center;
                }
            }
        }
    }
}


header{

    background:#fcfcfc;

    min-height:177px;
    border-bottom:solid 2px white;

    .title-bar{
        height:128px;
    }

    .top-bar{
        height:auto;
        position:relative;
        bottom:0;
        background:$black-color;
        color: white;
        padding: 0 0.5rem;
    
        ul{
            background: black;
            border: none;
            font-size:13px;

            ul.submenu{
                font-size:12px;
            }

            hr{
                margin:0;
            }

            a{
                color:black !important;
            }
        }

    }
}



header#page-header{
    z-index: 100;
    position: relative;
    transition: all 300ms ease;

    &.sticky{
        height: auto !important;
        min-height: 52px !important;
        position: fixed;
        width: 100%;

        #header-top{
            height: 0px !important;
            transition: all 300ms ease;
            opacity: 0;
            padding: 0px !important;
            overflow: hidden;
        }  

        + .main-content{
            padding-top:52px;
            min-height: calc(100vh - 52px - 85px);
        }   
    }

    .div-menu-hamburger{
        @include grid-column(2);
    }

    #page-logo{
        @include grid-column(8);
        font-size: 1.5rem;
/*
        background:url('/images/logo.png') no-repeat center center;
        background-size: contain;
*/

        .logoesi {
            background: url(/esipro/images/logo.png) no-repeat center center;
            background-size: contain;
            height: auto;
            width: 48%;
            float: right;
        }

        .logopf{
            background: url(/images/logo.png) no-repeat center center;
            background-size: contain !important;
            height: auto;
            width: 48%;
            float: left;
        }

        img{
            visibility: hidden;
        }
    }
    .top-bar{
        @include grid-column(2);
        text-transform:uppercase;

        #menu-menu-annexe-top{
            display: none;
        }
    }
    .search-bar{
        display: none;
        padding-top: 4px;
    }

    button.button-mobile{
        width: 40px;
        height: 40px;

        span.icon-bar
        {
            position: relative;
            transition: all 500ms ease-in-out;
            display: block;
            height: 2px;
            width: 20px;
            background: $red-color;
            margin: 0 auto;
            &:nth-of-type(1)
            {
                top: -2px;
            }
            &:nth-of-type(2)
            {
                top: 1px;
            }
            &:nth-of-type(3)
            {
                top: 4px;
            }
        }

        &[aria-expanded="true"]
        {
            span.icon-bar
            {
                &:nth-of-type(1)
                {
                    top: 3px;
                    transform: rotate(45deg);
                    width: 23px;
                }
                &:nth-of-type(2)
                {
                    background-color: transparent;
                }
                &:nth-of-type(3)
                {
                    top: 0px;
                    transform: rotate(-45deg);
                    width: 23px;
                }
            }
        }
    }
}

div.cats{
    > ul > li{
        display:table-cell;
        list-style:none;
        padding:0;

        &.cats-level-1 {
            padding: 15px !important;
        }       
    }
}

@media screen and (min-width: 768px){

    header#page-header{

        .div-menu-hamburger{
            display: none;
        }

        #page-logo{
            @include grid-column(3);
            @include grid-column-offset(0);

            font-size: 1.5rem;
        }

        .top-bar{
            @include grid-column(7);
            #menu-menu-annexe-top{
                display: block;
                margin-bottom: 10px;                
                
                li{
                    white-space:nowrap;

                    a{
                        font-size: 11px;
                    }
                }

            }
            text-transform: uppercase;
            font-weight: bold;

            &.main-nav{
                display: block;
                @include grid-column(12);
                @include grid-row();
                padding:0 !important;
                font-weight: normal;
                text-transform: none;
                text-align:center;

                ul > li{
                    &:hover, &[data-is-click="true"], &.is-active{
                        background:$pink-color;

                        > a{
                            color:$white-color !important;
                        }
                    }
                }
            }

            &.top a{
                color:#14438F;
            }
            &.bottom a{
                color:#000;
            }
            ul{
                background-color: transparent;

                &.menu > li:not(.menu-text) > a {
                    display: block;
                    padding: 10px 20px 10px 10px;
                    line-height: 1.3;
                    font-size: rem-calc(10px);

                    &:hover, &:focus{
                        outline: none;
                    }
                }
            }
        }
        .top-bar{
            display: inline-block;

            background:transparent;
            padding: 0;

            .top-bar-right{
                float:right;
                li{
                    padding: 5px;
                }
            }
        }
    }
}


@media screen and (min-width: 768px){

    header#page-header{

        .top-bar{
            &.main-nav{
                > ul{
                    display: table;
                    width: 100%;
                    height:52px;
                }
            }

            ul{

                &.menu > li:not(.menu-text) > a {
                    line-height: 1.3;
                    font-size: rem-calc(13px);
                }
            }
        }
    }
}

.is-dropdown-submenu {
    top: 40px;
    left: 0;
    min-width:inherit;
    text-align:left;
    background-color : #fcfcfc !important;
    min-width: 100%;
    width: auto;
    white-space: nowrap;
}

.off-canvas{
    background:$pink-color;

    .menu.vertical {
        background: $pink-color ! important;
    }
}

#offCanvasLeft2{
    .js-drilldown-back > a{
        &::before{
            border-color:transparent $white-color transparent transparent;      
            height:14px;
        }
    }

    .is-drilldown-submenu-parent > a::after{
        border-color:transparent transparent transparent $white-color;  
    }   

    a{
        color:$white-color !important;

        &:hover{
            color: $red-color !important;
        }
    }

    .is-dropdown-submenu-parent{
        position:inherit;
    }

    .is-dropdown-submenu {
        top: 0px;
        left: 100%;
        text-align: left;
        width: 200px;
        white-space: nowrap;
        position: absolute;
        border:none;
    }   
}

#menu-main-nav-horizontal > li, .menu.horizontal > li{
    display: inline-table !important;
    text-align: center !important;
    width: calc(100% / 8) !important;
    height:52px;

    > a{
        display:table-cell !important;
        vertical-align:middle;
    }
}

.is-drilldown-submenu.is-active {
    margin-left:0 !important;
}