
a.delete-cart-product {
    font-size: 10px;
    font-style: italic;
    color: #E2001A !important;
    text-align: right;
    display: block;
    float: right;
}

ul.checkout-steps{
	color:$white-color !important;
    display: table;
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
	table-layout:fixed;
	width:100%;


	.checkout-step   {
		display: block;
		float: left;
		height: 30px !important;
		margin: 0;
		padding: 0 0 0 30px;
		position: relative;
		width: 20%;
		line-height: 30px;
		font-size:10px;

		.checkout-step-anchor{
			background-color: $pink-color;
			z-index: 3;
			left: -5px;
			position: relative;
			color:$white-color !important;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: center;
			height:30px !important;


		}

		@media screen and (min-width:768px){
			display:table-cell;
			width:auto;
			float:none;
		}

		&:before, &:after{
			background-color: $pink-color;
			border: 15px solid transparent;
			border-left-color: #fff;
			content: '';
			display: block;
			height: 30px !important;
			left: 0px;
			padding: 0;
			position: absolute;
			top: 0;
			width: 0;
			z-index: 4;
		}

		&:after{
			background-color: #fff;
			border-left-color: $pink-color;
			left: auto;
			right: -15px;
			z-index: 4;
		}

		&.active{
/*
			background-color: $red-color;
			color:$white-color;
*/
			.checkout-step-anchor{
				background-color: $red-color;
				color:$white-color;
			}

			a{
				color:$white-color !important;
			}
			&:before, &:after{
				background-color: $red-color;
			}

			&:after{
				background-color: $white-color;
				border-left-color: $red-color;
			}
		}
	}
}

#delivery_providers_list{
    position:relative;

    &.waiting{

        @keyframes spinner {
          to {transform: rotate(360deg);}
        }

        &::before{
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border: 2px solid #ccc;
            border-top-color: #333;
            animation: spinner .6s linear infinite;
        }

        &::after{
            content: '';
            background: $red-color-rgba-10;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.add-delivery-address{
	color: #43AC6A !important;
}

div.full_delivery_address{
	font-size: 12px;
	margin: 5px 20px 0px 0px;
	font-style: italic;
}

select[name="deliveryAddress"] ~ div.full_delivery_address{
	display:none;
}

input[name="deliveryAddress"]{

	&:checked{
		+ label + div.full_delivery_address {
			display:block;
		}
	}

	+ label + div.full_delivery_address {
		display:none;
	}
}

.provider-description{
	font-size:12px;
}



