.input-group-field[type="number"]{
	text-align:center;
}

.input-group-label {
	background-color:$pink-color;
	color:black;
	border:none;
}

select{
	margin:0 !important;
}

.form-control{
	border: 1px solid #E5EBEC !important;
	outline:none;
}

.form-group {
    margin-bottom: 16px;
}

div.form-control{
    padding: 10px;
    border-radius: 25px;
    box-shadow: none;
    border: 0.0625rem solid #d1d3d5;
    border-radius: 1.25rem;
    padding-left: 1.5625rem;
    padding-right: 2.1875rem;
    font-size: 0.8125rem;
    line-height: 0.8125rem;	
}



.birth-field {
	border: 0.0625rem solid #d1d3d5;
	border-radius: 1.25rem;
	position: relative;
	margin-bottom: 0.9375rem; 
}

.birth-field::before, .birth-field::after {
	content: ' ';
	display: table; 
}

.birth-field::after {
	clear: both; 
}

.birth-field input {
	width: 30%;
	border: none;
	background-color: transparent;
	padding-right: 0;
	padding-left: 0.9375rem; 
}

.birth-field input:first-child {
	border-right: 0.0625rem solid #d1d3d5; 
	border:none;
}

.birth-field input:first-child:focus {
	border-left: none;
	background-color: transparent; 
}

.birth-field input:last-child {
	border-left: 0.0625rem solid #d1d3d5; 
	border:none;
}

.birth-field input:last-child:focus {
	border-right: none; 
}

.birth-field input:focus {
	border-top: none;
	border-bottom: none;
	background-color: transparent; 
}

.birth-field select {
	width: 40%;
	height: 2.5rem;
	margin: 0 2%; 
}

.birth-field input, .birth-field select {
	float: left;
	margin-bottom: 0;
	border-radius: 0;
	position: relative; 
}

.birth-field .fields {
	z-index: 2;
	width: 100%; 
}

.birth-field .placeholder {
	position: absolute;
	left: 1.5625rem;
	top: 0.75rem;
	z-index: 3;
	pointer-events: none; 
}

.birth-field .fields, .birth-field .placeholder {
	-webkit-transition: ease 200ms opacity;
	transition: ease 200ms opacity; 
}

.birth-field.active .fields {
	opacity: 1; 
}

.birth-field.active .placeholder {
	opacity: 0; 
}

.form-row {
	
	select{
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 20px;
	    border-color:#d1d3d5;
		font-size: 14px;
		padding: 0 25px 0 10px;
		line-height: 2rem;
	}
	
	div.birth-field select{
		border:none;

	}

}


.has-error {
	position: relative; 
}

.has-error span, .has-error legend {
    color: #f05471 !important;
}

.has-error input, .has-error textarea, .has-error .select-w, .has-error.select-w, .has-error .birth-field, .has-error .custom-check-w .icon, .has-error select {
	border-color: #f05471 !important;
	 
}

.has-error label{
	color: #f05471; 
}

.has-error .input-w .icon {
	color: #f05471; 
}

.has-error .input-w.file-field {
	border-color: #f05471 !important; 
}

.has-error .input-w.file-field .btn-in {
	color: #F05471 !important; 
}

.has-error .input-w .error-txt {
	position: relative !important;
	bottom: -4px !important;
	float: right; 
}

.has-error.file-w .input-w {
	border: 0.125rem #F05474 solid !important; 
}

.has-error.file-w .error-txt {
	position: relative !important;
	bottom: -4px !important;
	float: right; 
}

.error-txt {
	color: #f05471;
	font-size: 0.6875rem;
	font-size: 0.6875rem;
	line-height: 0.6875rem;
	text-align: right;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -1rem;
	overflow: hidden; 
}

.full .error-txt {
	bottom: 0; 
}

.input-group-button{
	a, input, button, label{
		font-size:14px;
	}
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
	margin:0;
}

.form-row{
	padding-top:5px;
	padding-bottom:15px;
}

.form-row::before, .form-row::after {
	content: ' ';
	display: table; 
}

.form-row::after {
	clear: both; 
}

.form-row ::-webkit-input-placeholder {
	color: #2e3941;
	opacity: 0.6;
	font-style: italic; 
}

.form-row :-moz-placeholder {
	color: #2e3941;
	opacity: 0.6;
	font-style: italic; 
}

.form-row ::-moz-placeholder {
	color: #2e3941;
	opacity: 0.6;
	font-style: italic; 
}

.form-row :-ms-input-placeholder {
	color: #2e3941;
	opacity: 0.6;
	font-style: italic; 
}

.form-row.head {
	padding-bottom: 1.875rem; 
}

.form-row.m-top {
	margin-top: 1.25rem; 
}

.form-row .input-w {
	position: relative;
	display: block; 
}

.form-row .input-w .icon {
    position: absolute;
    z-index: 2;
    right: 1.0625rem;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    color: #cbcfd4;
    pointer-events: none;
    padding: 0.3125rem 0 0.3125rem 0.3125rem;
}

.form-row .input-w input[readonly]:focus + .icon {
    color: #cbcfd4; 
}

.form-row .input-w input[readonly] + .icon {
    color: white; 
}
.form-row .input-w.high .icon {
    top: 1.6875rem; 
}

.form-row .input-w.disabled .icon {
    background-color: transparent; 
}

.form-row input, .form-row textarea, .form-row select {
	font-size: 0.8125rem;
	line-height: 0.8125rem; 
}

.form-row input, .form-row textarea, .form-row .select-w {
	box-shadow: none;
	border: 0.0625rem solid #d1d3d5;
	border-radius: 1.25rem;
	padding-left: 1.5625rem;
	padding-right: 2.1875rem; 
}

.form-row input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 62.5rem white inset; 
}

.form-row select {
	border: none;
	margin: 0;
	height: 2.1875rem; 
}

.form-row .select-w {
	padding: 0.125rem 0.625rem;
	margin-bottom: 0.9375rem;
	background-color: white; 
}

.form-row textarea {
	min-height: 9.375rem;
	border-radius: 0.375rem;
	padding-top: 1.25rem; 
}

.form-row .btn {
	display: block;
	width: 100%; 
}

fieldset {
    border: 1px solid #DDDDDD;
    margin: 1rem 0;
    padding: 1.11111rem;
    
    legend{
        font-weight: bold;
        margin: 0;
        margin-left: -0.16667rem;
        padding: 0 0.16667rem;  
    }
}

.form-row-button{
	.button{
		margin: 0;
	}
}

