
#login{
	form{
		border:solid 2px $pink-color;
		padding:15px;
		position:relative;
    	padding-bottom: 75px;

		.header-block-login{
			.fa{
				font-size: 80px;
			}
		}

		.footer-block-login{
			position:absolute;
			bottom:0px;
			left:50%;
			transform:translateX(-50%);
		    width: 70%;



			.mdp-lost {
			    font-size: 11px;
			    margin-bottom:10px;
			}
		}
	}
}

.my-space-menu {
	display: block;
	background:$pink-color;
	color:white;

	ul.menu {
		padding: 0;
		margin: 0;
		list-style: none;
		list-style-image: none;

		.menu-item {
			display:block;
			text-transform: uppercase;
			position:relative;

			&::after{
				content: "";
				height: 1px;
				width: 100%;
				background: white;
				position: absolute;
			}

			.menu-link {
				padding: 0.75rem;
				display: block;
				color:$white-color !important;
			}

			&.active{
				.menu-link {
					background-color: $red-color;
					color:$white-color !important;
				}
			}

			&:hover{
				ul.sub-menu{
				    max-height: 8000px;
				    overflow: visible;
				    transition: 0.5s all ease-in;
				}
			}
		}

		.sub-menu{
		    max-height: 0;
		    overflow: hidden;
		    transition: 1s all ease-in;
		    margin-left:20px;

		    &::before{
			    position: absolute;
			    content: "";
			    height: 1px;
			    width: 100%;
			    background: white;
		    }

    		.menu-item {
			    font-size: 12px;
			}
		}
	}
}

.address-item{
    margin-bottom: 15px;
    outline: none;

    &:not(.billing_address){
		.full_delivery_address{
			padding:10px 15px;
			border:solid 1px $pink-color;
		}

		&:hover{
			transform: translateY(-10px);
			transition: ease-in 200ms all;
			cursor: pointer;
		}
	}

    &.billing_address{
		.full_delivery_address{
			padding:10px 15px;
			background: $pink-color;
			color:$white-color;
		}
	}
}

span[class*="order-state-"]{
	font-size:11px;
	font-style:italic;
	border-style:solid;
	border-width:1px;
    padding: 3px 10px;
    border-radius: 15px;
	width: 100%;
    display: inline-block;
}

.order-state-1{
	color:grey;
	border-color:grey;
}

.order-state-2{
	color:orange;
	border-color:orange;
}

.order-state-3{
	color:orange;
	border-color:orange;
}

.order-state-4{
	color:green;
	border-color:green;
}

.order-state-5{
	color:$red-color;
	border-color:$red-color;
}

.order-state-6{
	color:$black-color;
	border-color:$black-color;
}

ul#market_cats {
    margin: 0;
    padding: 0;

	li {
	    display: inline-block;
	    width: 33%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover{
			a{
				color:$pink-color !important;

	    		&::before{
					content: '>';
					padding-right:5px;
					margin-left: -14px;
	    		}

			}
		}
	}
}


.has-form {
  position: absolute;
  top: 0;
  left: 6rem; // adjust distance from left here
  min-width: 14rem; // adjust width here

  @media only screen and (max-width: 40em) {
    min-width: 10rem;
    top: 0.5rem;

    .button {
      height: 1.85rem;
    }
  }
}

article.container-read-more {
    padding: 1.25rem;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 3.125rem;
    margin-bottom: 1.875rem;
    position:relative;
    border:solid 2px $pink-color;

    img.thumb{
        height: auto !important;
	    float: left;
	    width: 12.5rem;
	    display: block;
	    margin-right: 0.9375rem;
    }

    h3.title{
	    font-weight: 700;
	    font-size: 1.5rem;
	    line-height: 1.875rem;
	    color: $red-color;
	    margin-top:0;
    }

    p.desc{
	    height: auto !important;
	    display: table;
    }

    a{
	    margin-top: 5px !important;
	    font-weight: bold;
	    -webkit-transition: .5s;
	    transition: .5s;
	    display: block;
	    text-decoration: none !important;
		position: absolute;
		bottom: 0;
		right: 1.25rem;
    }
}

